<ng-container *ngIf="control">
  <label class="mb-0">
    {{ label }}
    <span
      *ngIf="required"
      class="color-red pl-0-25"
      >*</span
    >
  </label>
  <kendo-combobox
    #select
    (filterChange)="filterDropdown($event)"
    [clearButton]="true"
    [data]="dropdownCollection$ | async"
    [disabled]="disabled"
    [filterable]="true"
    [formControl]="selectorControl"
    [loading]="isLoading$ | async"
    [valueField]="valueField"
    textField="searchString"
  >
    <ng-template
      kendoComboBoxItemTemplate
      let-dataItem
    >
      @if (dataItem?.ticker) {
        <div class="container-security-item flex align-items-center cursor w-100 ml-2 my-1">
          <div
            [ngStyle]="{ background: addAlpha(dataItem?.color) }"
            class="icon-container"
          >
            <span [ngStyle]="{ color: dataItem?.color }">
              {{ getCurrentSymbol(dataItem.ticker) }}</span
            >
          </div>
          <div class="info-container flex-grow-1">
            <div class="title">
              <span
                *ngIf="dataItem?.assetType !== 'option'"
                innerHTML="{{ dataItem.description | highlight: (searchText$ | async) }}"
              ></span>
              <span
                *ngIf="dataItem.ticker && dataItem?.assetType !== 'option'"
                class="dot"
              ></span>
              <span innerHTML="{{ dataItem.ticker | highlight: (searchText$ | async) }}"></span>
            </div>
            <div class="flex justify-content-between">
              <div class="sub-title">
                <span class="assettype">{{ dataItem?.assetType }}</span>
                <ng-container *ngIf="dataItem?.assetType === 'equity'; else otherAssetType">
                  <span>
                    <ng-container *ngIf="dataItem?.sedol; else showCusip">
                      {{ dataItem?.sedol }}
                    </ng-container>
                    <ng-template #showCusip>
                      {{ dataItem?.cusip }}
                    </ng-template>
                  </span>
                </ng-container>
                <ng-template #otherAssetType>
                  <span>{{ dataItem?.cusip }}</span>
                </ng-template>
              </div>
              <div class="flex gap-2 align-items-center">
                <ng-container *ngIf="dataItem?.longPosition !== 0">
                  <div class="amount-container">
                    <i class="custom-icon-top-chart"></i>
                    <span class="amount">{{ dataItem?.longPosition | number: '1.0' }} </span>
                  </div>
                </ng-container>
                <ng-container *ngIf="dataItem?.shortPosition !== 0">
                  <div class="amount-container">
                    <i class="custom-icon-low-chart"></i>
                    <span class="amount">{{ dataItem?.shortPosition | number: '1.0' }} </span>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      }
    </ng-template>
  </kendo-combobox>
</ng-container>
